<template>
    <div :class="['flex flex-col', styles['wrapper']]">
        <div class="flex flex-col">
            <div class="flex justify-between">
                <label :class="['block text-sm font-medium mb-1', error ? 'text-red-700' : 'text-gray-700']">{{ label }}</label>
                <div v-if="!loading && options.length > 1" class="flex space-x-2">
                    <div v-if="selected.length > 0" class="hover:text-blue-700 cursor-pointer" @click="deselectAll">Deselect all</div>
                    <div v-if="selected.length !== options.length" class="hover:text-blue-700 cursor-pointer" @click="selectAll">Select all</div>
                </div>
            </div>

            <div :class="['border rounded pr-4 pl-3 pt-4 pb-2 md:pr-6 md:pl-5 md:pt-6 md:pb-4', error ? 'border-red-700' : 'border-gray-200']">
                <div v-if="loading" class="flex items-center">
                    <span class="fas fa-spinner-third fa-spin mr-2"></span>
                    <translate>Loading</translate>
                    ...
                </div>

                <div v-if="!loading">
                    <div v-if="orderedOptions.length < 1" class="pb-2">No results</div>

                    <div v-else class="flex flex-wrap">
                        <div v-for="(option, index) in orderedOptions"
                             :key="option.id"
                             :class="['flex items-center justify-between md:justify-start cursor-pointer mb-1', styles['row-classes'], index%2 === 0 ? 'md:pr-2' : 'md:pl-2']"
                             @click="onChange(option.id)">
                            <div :class="['flex items-center truncate hover:text-blue-700', error ? 'text-red-700' : 'text-gray-700']">
                                <div class="p-1">
                                    <input type="checkbox"
                                           class="focus:ring-blue-500 h-5 w-5 text-blue-600 border-gray-300 rounded"
                                           :checked="selected.includes(option.id)">
                                </div>
                                <div class="sm:text-sm ml-2 truncate">{{ option.name }}</div>
                            </div>
                            <i v-if="isEditable" class="fal fa-pencil ml-3 hover:text-blue-500 cursor-pointer" @click="edit(option)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span v-if="error" class="mt-1 text-red-700 text-xs"><i class="fal fa-exclamation-triangle mr-2"/>{{ error }}</span>
    </div>
</template>

<script>
const STYLES = {
    'wrapper': 'w-full',
    'row-classes': 'w-full md:w-1/2'
}
import {each, filter, find, orderBy} from "lodash";

export default {
    name: "selector-checkboxes",
    props: {
        options: {type: Array, default: () => []}, // [{id: 1, name: 'blah'}, {id: 2, name: 'blah'}]
        selectedOptions: {type: Array, default: () => []}, // [{id: 1, name: 'blah'}, {id: 2, name: 'blah'}]
        label: {type: String, default: 'Choose options:'},
        error: {type: String, default: null},
        loading: {type: Boolean, default: false},
        isEditable: {type: Boolean, default: false},
        styles: {type: Object, default: () => STYLES}
    },
    data() {
        return {
            selected: []
        }
    },

    watch: {
        selected(newSelectedOptions) {
            this.$emit('on-change', newSelectedOptions)
        },

        selectedOptions(newOptions) {
            this.selected = newOptions
        }
    },

    computed: {
        orderedOptions() {
            return orderBy(this.$props.options, ['name'], ['asc'])
        }
    },

    methods: {
        selectAll() {
            const allOptions = [];
            each(this.$props.options, option => allOptions.push(option.id));
            this.selected = allOptions
        },
        deselectAll() {
            this.selected = []
        },

        onChange(optionId) {
            const found = find(this.selected, item => item === optionId);
            this.selected = found ? filter(this.selected, item => item !== optionId) : [...this.selected, optionId];
        },

        edit(item) {
            this.$emit('on-edit', item)
        }
    },
    created() {
        this.selected = this.$props.selectedOptions;
    }
}
</script>

<style scoped>

</style>