<template>
    <selector-checkboxes :options="roles"
                         label="Select roles"
                         :selected-options="selectedRoles"
                         :error="fetchError || error"
                         :loading="loading"
                         @on-change="onChange"
    />
</template>

<script>
import axios from "axios";
import Selector from '../../../../../components/selector-checkboxes';

export default {
    name: "RolesSelector",
    props: {
        selectedRoles: {
            type: Array,
            default: () => [] // [{id: 1, name: 'blah'}, {id: 2, name: 'blah'}]
        },
        error: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            fetchError: null,
            loading: false,
            roles: [],
        }
    },

    watch: {
        selected(newVal) {
            this.$emit('on-change', newVal)
        }
    },

    methods: {
        async fetchRoles() {
            try {
                this.loading = true;
                this.fetchError = null;
                const response = await axios.get('/manager/expert3/xp3/get-roles/', {withCredentials: true})

                if (response.data.result === 'Ok') {
                    this.roles = response.data.roles;
                } else {
                    this.fetchError = 'Loading roles failed. Please try again.'
                }
                this.loading = false;
            } catch (err) {
                this.fetchError = 'Loading roles failed. Please try again.'
                this.loading = false;
            }
        },

        onChange(newSelectedRoles) {
            this.$emit('on-change', newSelectedRoles)
        }
    },

    created() {
        this.fetchRoles();
    },
    components: {
        'selector-checkboxes': Selector
    }
}
</script>

<style scoped>

</style>